 @import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~@angular/material/theming';
@include mat-core();

$candy-app-primary: mat-palette($mat-indigo);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);
$candy-app-warn:    mat-palette($mat-red);

$candy-app-theme: mat-light-theme(
    $candy-app-primary, $candy-app-accent, $candy-app-warn);

@include angular-material-theme($candy-app-theme);

.dark-theme {
    $primary-dark: mat-palette($mat-pink, 700);
    $accent-dark: mat-palette($mat-green);
    $warn-dark: mat-palette($mat-red);

    $theme-dark: mat-dark-theme($primary-dark, $accent-dark, $warn-dark);

    @include angular-material-theme($theme-dark);
}


$vendor-onboarding-primary: mat-palette($mat-cyan, 300, 300, 900);
$vendor-onboarding-accent: mat-palette($mat-light-blue);
$vendor-onboarding-warn: mat-palette($mat-lime, A200);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$vendor-onboarding: mat-light-theme(
    $vendor-onboarding-primary,
    $vendor-onboarding-accent,
    $vendor-onboarding-warn
);


/* $my-app-primary: mat-palette($mat-blue-grey);
$my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
$my-app-warn:    mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
.alternate-theme {
  $alternate-primary: mat-palette($mat-light-blue);
  $alternate-accent:  mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
} */


body, html {
  margin:0;
  padding:0;
}

h2, h3 {
  font-size:1em;
  font-weight:bold;
}

h4 {
  font-size:1em;
}

/* div.card-header {
  font-size: large;
}

div.card {
  margin-top: 10px
}

.table {
  margin-top: 10px
} */


.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
 /* font-size: 19px;
   font-family:'Calibri', Helvetica, Arial, sans-serif; */
  // color: #323334;
  font-style:revert;
  font-weight: bolder;
}



.mat-card-subtitle, .mat-card-content{
 /* font-size:8px;
   font-family:'Calibri', Helvetica, Arial, sans-serif; */
  // font-family: "Book Antiqua";
  color: #323334;
}

.mat-input-element mat-form-field-autofill-control cdk-text-field-autofill-monitored{
  font-size:4px;
  /*font-family:'Calibri', Helvetica, Arial, sans-serif;*/
  // font-family: "Book Antiqua";
  color: #323334;
}

.mat-table {
  overflow-y:auto;
  max-height:50vh;
  min-height:220px;
}

.mat-row:nth-child(odd) {
  background-color:rgba(255,255,255,.67);
}
.mat-row:nth-child(even) {
  background-color:rgba(251,251,251,.5);
}

#buyBackVal {
     width: 99.2%;
  height: 40%;
}

#buyBackVDetVal {
  width: 99.2%;
  height: 48%;
}

.accent1-color {
  background-color: #630017 !important;
}

.download {
  text-align:right;
  margin:1em;
  cursor:pointer;
 /* font-size:.8em;
   font-family:'Calibri', Helvetica, Arial, sans-serif; */
  color:#323334;
}

.csv-button {
  color:white;
  background-color:#608e3a;
  display:inline-block;
  width:2.25em;
  text-align:center;
  border:none;
  border-radius:5px;
  padding-top:.33em;
  cursor:pointer;


  i {
    font-size:1.33em;
  }
}

.download:hover .csv-button {
  background-color:#709e4a;
}



.mat-raised-button {
  font-family: Roboto,"Helvetica Neue",sans-serif;
  /* font-size: 12px; */
  font-weight: bold;
  color: #f8f9ff;
}


.mat-card {
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  height: 80%;
}

.container-fluid {
  height: 100%;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.form-group {
  margin-bottom: 2px;
}

.layout{
  padding: 20px;
}

.innerDiv {
  padding: 10px;
}
input.ng-invalid.ng-touched {
  border: 1px solid red;
}

.mat-tooltip.help-tooltip {
  background: white;
  border: rgba(0,0,0,.5) 1px solid;
  color: rgba(0,0,0,.67);
  position:relative;
  overflow: visible;
  box-shadow: 5px 5px 10px -5px rgba(0,0,0,0.25);
  font-size:.825em;
}

.mat-tooltip.help-tooltip:before {
  content: '';
  position:absolute;
  left:-10px;
  top:calc(50% - 5px);
  height:0;
  width:0;
  border-right:rgba(0,0,0,.5) 5px solid;
  border-left:transparent 5px solid;
  border-top:transparent 5px solid;
  border-bottom:transparent 5px solid;

}


